import React from "react"
import { WithSquadBet, useSquadBet } from "./context"
import DisplaySaveResult from "@root/bet_utils/DisplaySaveResult"
import _ from "lodash"
import I18n from "i18n-js"
import betStatus from '@root/bet_utils/presenter/bet_status'
import CancelledInfo from '@root/bet_utils/CancelledInfo'
import PostponedInfo from '@root/bet_utils/PostponedInfo'
import {
  BENCH_INDEX,
  SUB_PLAYER_INDEX,
  SquadBetPlayerSlot,
  SquadBetTeamSlot,
  TEAM_INDEX,
  sortPlayers,
  getPlayersFromSlot
} from "./helper"
import { useDevice } from "@root/misc/device"
import BetStatus from "@root/bet_utils/BetStatus"
import usePicker, { PickerEntry, WithPicker } from "./usePicker"
import { SquadBetPlayer, SquadBetTeam } from "./types"
import { WithStats, useStatsBoundToBet } from "./useStats"
import {
  EmptyItemInner,
  PickItem,
  PickItemHolder,
  PickItemHolderProps,
  PlayerDummyItemInner,
  PlayerItemInner,
  PlusItemInner,
  SquadBetCountdown,
  SquadPlayerItemsHolder,
  SquadSection,
  TeamItemInner,
  TiebreakerQuestion,
} from "./ui"

const locationImgContext = require.context("@images/", true, /location--.*/)

export const DayHeader: React.FC<{}> = ({}) => {
  const {
    bet
  } = useSquadBet()
  return <div className="squadBet__dayHeader">
    <h2>
      <span className="squadBet__dayHeader__title">{I18n.t("squadbet.day_header", {day_num: bet.day_index + 1})}</span>
      <span className="squadBet__dayHeader__date">{betStatus.prettyDateOrCancelledOrTBD(bet, "MMM D, HH:mm")}</span>
    </h2>
    <BetStatus
      cssNamespace="squadBet__betStatus"
      overAndScored={(score: number) => <><span>{I18n.t("squadbet.day_score")}</span><strong><ScoreValue score={score} /></strong></>}/>
  </div>
}

const MatchdayHeader: React.FC = () => {
  const { isPhone } = useDevice()
  const {
    bet
  } = useSquadBet()

  const Title = <h2 className="squadBet__matchdayHeader__title">{bet.event_name}</h2>
  const EventDate = <span className="squadBet__matchdayHeader__eventDate">{bet.event_subtitle}</span>
  const CountdownArea = <div className="squadBet__matchdayHeader__countdownHolder"><SquadBetCountdown secondsLeft={betStatus.isMatchOpen(bet) ? bet.secondsLeft : 0} /></div>
  const locationImgSrc = locationImgContext(`./location--${bet.location.toLocaleLowerCase()}.jpg`)

  return <div className="squadBet__matchdayHeader__wrapper">
    {isPhone
      ? <div className="squadBet__matchdayHeader__phone">
          <div className="squadBet__matchdayHeader__phone__titleHolder">{Title}</div>
          <div className="squadBet__matchdayHeader__phone__locationImg" style={{backgroundImage: `url(${locationImgSrc})`}} />
          <div className="squadBet__matchdayHeader__phone__dateAndCountdownHolder">
            {EventDate}
            {CountdownArea}
          </div>
        </div>
      : <div className="squadBet__matchdayHeader__desktop">
          <div className="squadBet__matchdayHeader__desktop__titleAndCountdownHolder">
            {Title}
            {EventDate}
            {CountdownArea}
          </div>
          <div className="squadBet__matchdayHeader__desktop__locationImg" style={{backgroundImage: `url(${locationImgSrc})`}} />
        </div>}
    </div>
}

const MatchdayHeaderJustCountdown: React.FC = () => {
  const { isPhone } = useDevice()
  const {
    bet
  } = useSquadBet()

  return betStatus.isMatchOpen(bet)
    ? <div className="squadBet__matchdayHeaderJustCountdown__wrapper">
        <SquadBetCountdown secondsLeft={bet.secondsLeft} />
      </div>
    : null
}

const defaultPropsForPlayerItemHolder = (s: SquadBetPlayerSlot): Partial<PickItemHolderProps> => {
  return {
    aboveTitle: s.player.captain ? I18n.t("squadbet.label_captain") : undefined,
    title: s.player.name,
    stats: s.player.stats ? [
      [I18n.t("squadbet.stat_player_rank_label"), I18n.t("squadbet.stat_player_rank", {rank: s.player.stats.rank, total: s.player.stats.total_ranks})],
      [I18n.t("squadbet.stat_picked_by_label"), `${s.player.stats.perc_picked}%`],
    ] : undefined
  }
}

const defaultPropsForTeamItemHolder = (t: SquadBetTeamSlot): Partial<PickItemHolderProps> => {
  return {
    title: t.team.name,
    stats: t.team.stats ? [
      [I18n.t("squadbet.stat_team_rank_label"), I18n.t("squadbet.stat_team_rank", {rank: t.team.stats.rank ?? "0", total: t.team.stats.total_ranks})],
      [I18n.t("squadbet.stat_picked_by_label"), `${t.team.stats.perc_picked}%`],
    ] : undefined
  }
}

export const ScoreValue: React.FC<{score?: number, replace0withE?: boolean}> = ({score, replace0withE}) => {
  return <span className="squadBet__scoreValue">
    {_.isNumber(score)
      ? (score === 0
          ? replace0withE ? "E" : score
          : (score < 0
            ? `-${-score}`
            : `+${score}`))
      : "-"}
  </span>
}

const ScoreBelowPlayer: React.FC<{score: number, replace0withE?: boolean}> = ({score, replace0withE}) => {
  return <strong className="squadBet__scoringDetails__score"><ScoreValue score={score} replace0withE={replace0withE} /></strong>
}

const SelectSquad: React.FC = () => {
  const {
    bet,
    squadSlots,
    changePicks
  } = useSquadBet()
  const {
    pickPlayer: pickerPickPlayer,
    pickTeam: pickerPickTeam
  } = usePicker()
  const { showStatsForPlayer, showStatsForTeam } = useStatsBoundToBet()

  const pickPlayer = (slotIndex: number) => {
    const playerInSlot = squadSlots.squadSlots[slotIndex]?.player
    const numPickedCaptains = squadSlots.amountPickedCaptains(playerInSlot)

    const playersForSlot = getPlayersFromSlot(bet, slotIndex)

    pickerPickPlayer(_.map(playersForSlot.sort(sortPlayers), (p) => {
      let cantPickReason: string = ""
      let canPick: Boolean = true
      const alreadyInSquad = _.some(squadSlots.allPickedPlayers(), (pl) => pl.id === p.id)
      if (!alreadyInSquad) {
        if (p.captain && numPickedCaptains >= 1) {
          canPick = false
          cantPickReason = I18n.t("squadbet.cant_pick_because_captain")
        } else if (squadSlots.amountPickedPlayersPerTeam(p.team_key) >= 2) {
          canPick = false
          cantPickReason = I18n.t("squadbet.cant_pick_because_team")
        }
      }
      const entry: PickerEntry<SquadBetPlayer> = {
        item: p,
        alreadyInSquad,
        cantPickReason: canPick ? undefined : cantPickReason,
        sameAsAlreadyInThisSlot: p.id === bet.picks?.[slotIndex]
      }
      return entry}),
      (item) => {
        console.log("player picked", item, slotIndex)
        changePicks((prev) => {
          const newPicks = _.map(prev, (p) => {
            if (!item) { return p }
            return p === item.id ? undefined : p
          }) // clone and clear out same item from other slots
          newPicks[slotIndex] = item?.id || undefined
          return newPicks
        })
      }
    )
  }

  const pickSub = () => {
    const playerInSlot = squadSlots.squadSlots[BENCH_INDEX]?.player
    const numPickedCaptains = squadSlots.amountPickedCaptains(playerInSlot)

    const playersForSlot = getPlayersFromSlot(bet, 4)
    pickerPickPlayer(_.map(playersForSlot.sort(sortPlayers), (p) => {
      let cantPickReason: string = ""
      let canPick: Boolean = true
      const alreadyInSquad = _.some(squadSlots.allPickedPlayers(), (pl) => pl.id === p.id)
      if (!alreadyInSquad) {
        if (p.captain && numPickedCaptains >= 1) {
          canPick = false
          cantPickReason = I18n.t("squadbet.cant_pick_because_captain")
        } else if (squadSlots.amountPickedPlayersPerTeam(p.team_key) >= 2) {
          canPick = false
          cantPickReason = I18n.t("squadbet.cant_pick_because_team")
        }
      }
      const entry: PickerEntry<SquadBetPlayer> = {
        item: p,
        alreadyInSquad,
        cantPickReason: canPick ? undefined : cantPickReason,
        sameAsAlreadyInThisSlot: p.id === bet.picks?.[BENCH_INDEX]
      }
      return entry}),
      (item) => {
        console.log("sub picked", item)
        changePicks((prev) => {
          const newPicks = _.map(prev, (p) => {
            if (!item) { return p }
            return p === item.id ? undefined : p
          }) // clone and clear out same item from other slots
          newPicks[BENCH_INDEX] = item?.id || undefined
          newPicks[SUB_PLAYER_INDEX] = item?.id || undefined
          return newPicks
        })
      }
    )
  }

  const pickTeam = () => {
    pickerPickTeam(_.map(_.sortBy(bet.teams, ["team_key"]), (t) => {
      const entry: PickerEntry<SquadBetTeam> = {
        item: t,
        alreadyInSquad: t.id === bet.picks?.[TEAM_INDEX],
        sameAsAlreadyInThisSlot: t.id === bet.picks?.[TEAM_INDEX]
      }
      return entry}),
      (item) => {
        console.log("team picked", item)
        changePicks((prev) => {
          const newPicks = [...prev]
          newPicks[TEAM_INDEX] = item?.id || undefined
          return newPicks
        })
      }
    )
  }

  return <SquadSection
    title={I18n.t(`squadbet.squad_title--pick`)}
    squadItems={_.map(squadSlots.squadSlots, (s, index) => {
      return s
        ? <PickItemHolder key={index} {...defaultPropsForPlayerItemHolder(s)}
            actionButton={{
              text: I18n.t("squadbet.action_show_player_stats"),
              action() {showStatsForPlayer(s.player)}}}>
            <PickItem actionable={true} onClick={() => pickPlayer(index)} withChangeIcon>
              <PlayerItemInner player={s.player} />
            </PickItem>
          </PickItemHolder>
        : <PickItemHolder key={index}
            placeholderTitle={I18n.t("squadbet.action_pick")}>
            <PickItem actionable={true} onClick={() => pickPlayer(index)}>
              <PlusItemInner />
            </PickItem>
          </PickItemHolder>
    })}
    subTitle={I18n.t("squadbet.sub_title")}
    subInfo={I18n.t("squadbet.sub_info--pick")}
    subItem={squadSlots.benchSlot
      ? <PickItemHolder {...defaultPropsForPlayerItemHolder(squadSlots.benchSlot)}
          actionButton={{
            text: I18n.t("squadbet.action_show_player_stats"),
            action: () => squadSlots.benchSlot && showStatsForPlayer(squadSlots.benchSlot.player)}}>
          <PickItem actionable={true} onClick={() => pickSub()}>
            <PlayerItemInner player={squadSlots.benchSlot.player} />
          </PickItem>
        </PickItemHolder>
      : <PickItemHolder
          placeholderTitle={I18n.t("squadbet.action_pick")}>
          <PickItem actionable={true} onClick={() => pickSub()}>
            <PlusItemInner />
          </PickItem>
        </PickItemHolder>}
    teamTitle={I18n.t(`squadbet.team_title--pick`)}
    teamInfo={I18n.t("squadbet.team_info--pick")}
    teamItem={squadSlots.teamSlot
      ? <PickItemHolder {...defaultPropsForTeamItemHolder(squadSlots.teamSlot)}>
          <PickItem actionable={true} onClick={() => pickTeam()} withChangeIcon>
            <TeamItemInner team={squadSlots.teamSlot.team} />
          </PickItem>
        </PickItemHolder>
      : <PickItemHolder
          placeholderTitle={I18n.t("squadbet.action_pick")}>
          <PickItem actionable={true} onClick={() => pickTeam()}>
            <PlusItemInner />
          </PickItem>
        </PickItemHolder>} />
}

const SelectedSquad: React.FC = () => {
  const {
    bet,
    squadSlots
  } = useSquadBet()
  const { showStatsForPlayer, showStatsForTeam } = useStatsBoundToBet()

  const titleSuffix = bet.from_other ? "other" : "own"

  return <SquadSection
    title={I18n.t(`squadbet.squad_title--${titleSuffix}`)}
    squadItems={_.map(squadSlots.squadSlots, (s, index) => {
      return s
      ? <PickItemHolder key={index} {...defaultPropsForPlayerItemHolder(s)}
          actionButton={{
            text: I18n.t("squadbet.action_show_player_stats"),
            action() {showStatsForPlayer(s.player)}}}>
          <PickItem actionable={false}>
            <PlayerItemInner player={s.player} />
          </PickItem>
        </PickItemHolder>
      : <PickItemHolder key={index}>
          <PickItem actionable={false}>
            <EmptyItemInner emptyReason={bet.censored ? "censored" : "nothingPicked"} />
          </PickItem>
        </PickItemHolder>
    })}
    subTitle={I18n.t("squadbet.sub_title")}
    subInfo={I18n.t("squadbet.sub_info--closed")}
    subItem={squadSlots.benchSlot
      ? <PickItemHolder {...defaultPropsForPlayerItemHolder(squadSlots.benchSlot)}
          actionButton={{
            text: I18n.t("squadbet.action_show_player_stats"),
            action: () => squadSlots.benchSlot && showStatsForPlayer(squadSlots.benchSlot.player)}}>
          <PickItem actionable={false}>
            <PlayerItemInner player={squadSlots.benchSlot.player} />
          </PickItem>
        </PickItemHolder>
      : <PickItemHolder>
          <PickItem actionable={false}>
            <EmptyItemInner emptyReason={bet.censored ? "censored" : "nothingPicked"} />
          </PickItem>
        </PickItemHolder>}
    teamTitle={I18n.t(`squadbet.team_title--${titleSuffix}`)}
    teamInfo={I18n.t("squadbet.team_info--closed")}
    teamItem={squadSlots.teamSlot
      ? <PickItemHolder {...defaultPropsForTeamItemHolder(squadSlots.teamSlot)}
          // actionButton={{
          //   text: I18n.t("squadbet.action_show_team_stats"),
          //   action: () => squadSlots.teamSlot && showStatsForTeam(squadSlots.teamSlot.team)}}
            >
          <PickItem actionable={false}>
            <TeamItemInner team={squadSlots.teamSlot.team} />
          </PickItem>
        </PickItemHolder>
      : <PickItemHolder>
          <PickItem actionable={false}>
            <EmptyItemInner emptyReason={bet.censored ? "censored" : "nothingPicked"} />
          </PickItem>
        </PickItemHolder>} />
}

const SquadOrScoringForDay: React.FC = () => {
  const {
    bet,
    squadSlots
  } = useSquadBet()
  const { showStatsForPlayer, showStatsForTeam } = useStatsBoundToBet()

  const titleSuffix = bet.from_other ? "other" : "own"

  return <SquadSection
    title={I18n.t(`squadbet.squad_title--${titleSuffix}`)}
    squadItems={_.map(squadSlots.squadSlots, (s, index) => {
      return s
      ? <PickItemHolder key={index} {...defaultPropsForPlayerItemHolder(s)}
          greyout={_.isBoolean(s.scoreCounts) && !s.scoreCounts}
          below={<>
            {_.isNumber(s.score) &&
              <ScoreBelowPlayer score={s.score} replace0withE />}
            {_.isBoolean(s.automaticallySwappedIn) && s.automaticallySwappedIn &&
              <span className="squadBet__scoringDetails__automaticallySwappedIn">{I18n.t("squadbet.automatically_swapped_in_info")}</span>}
          </>}
          actionButton={{
            text: I18n.t("squadbet.action_show_player_stats"),
            action() {showStatsForPlayer(s.player)}}}>
          <PickItem actionable={false}>
            <PlayerItemInner player={s.player} />
          </PickItem>
        </PickItemHolder>
      : <PickItemHolder key={index} greyout>
          <PickItem actionable={false}>
            <EmptyItemInner emptyReason={bet.censored ? "censored" : "nothingPicked"} />
          </PickItem>
        </PickItemHolder>
    })}
    subTitle={<span style={{opacity: 0, visibility: "hidden"}}>{I18n.t("squadbet.sub_title")}</span>}
    subInfo={I18n.t("squadbet.squad_day_eval_info")}
    subItem={squadSlots.benchSlot
      ? <PickItemHolder {...defaultPropsForPlayerItemHolder(squadSlots.benchSlot)}
          greyout
          below={<>
            {_.isNumber(squadSlots.benchSlot.score) &&
              <ScoreBelowPlayer score={squadSlots.benchSlot.score} replace0withE />}
          </>}
          actionButton={{
            text: I18n.t("squadbet.action_show_player_stats"),
            action: () => squadSlots.benchSlot && showStatsForPlayer(squadSlots.benchSlot.player)}}>
          <PickItem actionable={false}>
            <PlayerItemInner player={squadSlots.benchSlot.player} />
          </PickItem>
        </PickItemHolder>
      : <PickItemHolder greyout>
          <PickItem actionable={false}>
            <EmptyItemInner emptyReason={bet.censored ? "censored" : "nothingPicked"} />
          </PickItem>
        </PickItemHolder>}
    teamTitle={I18n.t(`squadbet.team_title--${titleSuffix}`)}
    teamInfo={I18n.t("squadbet.team_info--closed")}
    teamItem={squadSlots.teamSlot
      ? <PickItemHolder {...defaultPropsForTeamItemHolder(squadSlots.teamSlot)}
          greyout={!_.isNumber(bet.total_score)}
          below={<>
            {_.isNumber(squadSlots.teamSlot.score) &&
              <ScoreBelowPlayer score={squadSlots.teamSlot.score} replace0withE />}
          </>}
          // actionButton={{
          //   text: I18n.t("squadbet.action_show_team_stats"),
          //   action: () => squadSlots.teamSlot && showStatsForTeam(squadSlots.teamSlot.team)}}
            >
          <PickItem actionable={false}>
            <TeamItemInner team={squadSlots.teamSlot.team} />
          </PickItem>
        </PickItemHolder>
      : <PickItemHolder greyout>
          <PickItem actionable={false}>
            <EmptyItemInner emptyReason={bet.censored ? "censored" : "nothingPicked"} />
          </PickItem>
        </PickItemHolder>} />
}

const SelectSub: React.FC = () => {
  const {
    changePicks,
    squadSlots,
    bet
  } = useSquadBet()
  const { showStatsForPlayer } = useStatsBoundToBet()

  const unSwap = (index: number) => {
    console.log("unSwap", index)
    changePicks((prev) => {
      const newPicks = [...prev]
      newPicks[index] = squadSlots.benchSlot?.player.id
      newPicks[BENCH_INDEX] = squadSlots.substitutePlayer?.id
      return newPicks
    })
  }

  const swapIn = (index: number) => {
    console.log("swapIn", index)
    changePicks((prev) => {
      const newPicks = [...prev]
      newPicks[BENCH_INDEX] = prev[index]
      newPicks[index] = squadSlots.substitutePlayer?.id
      return newPicks
    })
  }

  return (squadSlots.substitutePlayer && _.compact(squadSlots.squadSlots).length > 0)
    ? <div className="squadBet__pickSub">
        <div className="squadBet__pickSub__info">{I18n.t("squadbet.interaction_sub_info")}</div>
        <SquadPlayerItemsHolder
          items={_.map(squadSlots.squadSlots, (s, index) => {
            const subAction: {type: "swapIn" | "unSwap",text: string, action(): void} | undefined =
              squadSlots.substituteSwappedIn
              ? (s
                ? (s.isSub
                  ? {type: "unSwap", text: I18n.t("squadbet.action_swap_back", {name: squadSlots.benchSlot?.player.name || ""}), action: () => unSwap(index)}
                  : undefined)
                : undefined)
              : {type: "swapIn", text: I18n.t("squadbet.action_swap_with", {name: squadSlots.benchSlot?.player.name || ""}), action: () => swapIn(index)}

            return s
              ? <PickItemHolder key={index} {...defaultPropsForPlayerItemHolder(s)}
                  greyout={false}
                  above={subAction
                    ? <><div className="squadBet__pickSub__pickButtonHolder"><a className={`squadBet__pickSub__pickButton squadBet__pickSub__pickButton--${subAction.type}`} onClick={() => subAction.action()}><span>{subAction.text}</span></a></div></>
                    : <><div className="squadBet__pickSub__topSpacer" /></>}
                  actionButton={{
                    text: I18n.t("squadbet.action_show_player_stats"),
                    action() {showStatsForPlayer(s.player)}}}>
                  <PickItem actionable={!!(subAction)} onClick={subAction?.action}>
                    <PlayerItemInner player={s.player} />
                  </PickItem>
                </PickItemHolder>
              : <PickItemHolder key={index}
                  greyout={false}
                  above={subAction
                    ? <><div className="squadBet__pickSub__pickButtonHolder"><a className={`squadBet__pickSub__pickButton squadBet__pickSub__pickButton--${subAction.type}`} onClick={() => subAction.action()}><span>{subAction.text}</span></a></div></>
                    : <><div className="squadBet__pickSub__topSpacer" /></>}>
                  <PickItem actionable={!!(subAction)} onClick={subAction?.action}>
                    <EmptyItemInner emptyReason="nothingPicked" />
                  </PickItem>
                </PickItemHolder>})} />
      </div>
    : <>
        <div className="squadBet__pickSub__cantBecauseNoNecessaryPlayers">
          {I18n.t("squadbet.cant_pick_sub_because_not_necessary_players")}
        </div>
      </>
}

const Upcoming: React.FC = () => {
  const {
    bet,
    squadSlots
  } = useSquadBet()

  return <>
    <div className="squadBet__upcoming">
      {bet.from_other
        ? I18n.t("squadbet.upcoming.other_user")
        : (!betStatus.isMatchOpen(bet) && !squadSlots.madeAnyPicks
          ? I18n.t("squadbet.upcoming.nothing_picked") // should only occur when first day missed
          : (bet.interaction_mode === "select-squad"
            ? I18n.t("squadbet.upcoming.select_squad")
            : I18n.t("squadbet.upcoming.select_sub")))}
    </div>
    {bet.interaction_mode === "select-substitute" && betStatus.isMatchOpen(bet) &&
      <div className="squadBet__upcoming__pickSubDummy">
        <SquadPlayerItemsHolder
          items={_.map(squadSlots.squadSlots, (ignore, index) => {
            return <div className="squadBet__dummyPlayer__holder" key={index}>
              <div className="squadBet__dummyPlayer__fakeSwapIcon" />
              <PickItem actionable={false}>
                <PlayerDummyItemInner />
              </PickItem>
            </div>
          })} />
      </div>}
  </>
}

const _SquadBet: React.FC = () => {
  const {
    bet,
    squadSlots,
    displaySaveResult,
    authToken
  } = useSquadBet()

  return <WithStats><div className="squadBet">
    {bet.day_index === 0 &&
      <MatchdayHeaderJustCountdown />}
    {bet.interaction_mode === "select-squad" &&
      (betStatus.canEdit(bet)
        ? <>
            <DisplaySaveResult classPrefix="squadBet" saveResult={displaySaveResult} />
            <WithPicker>
              <SelectSquad />
            </WithPicker>
          </>
        : <><SelectedSquad /></>)}
    {bet.tiebreaker_question && (
      <TiebreakerQuestion
        round={bet.round}
        question={bet.tiebreaker_question}
        answer={bet.tiebreaker_value}
        authToken={authToken}
        canEdit={betStatus.canEdit(bet)}
      />
    )}
    <div className="squadBet__dayWrapper">
      <DayHeader />
      {bet.cancelled || bet.postponed
        ? <>
            <CancelledInfo />
            <PostponedInfo />
          </>
        : (!betStatus.isMatchOpen(bet) && !bet.from_other && !squadSlots.madeAnyPicks // own closed without picks
            ? <Upcoming />
            : <>
                {bet.interaction_mode === "select-substitute" &&
                  (squadSlots.madeAnyPicks
                    ? (betStatus.canEdit(bet)
                      ? <>
                          <DisplaySaveResult classPrefix="squadBet" saveResult={displaySaveResult} />
                          <WithPicker>
                            <SelectSub />
                          </WithPicker>
                        </>
                      : <SquadOrScoringForDay />)
                    : <Upcoming />)}
                {bet.interaction_mode === "select-squad" &&
                  (betStatus.isMatchOpen(bet)
                    ? <Upcoming />
                    : <SquadOrScoringForDay />)}
              </>)}
    </div>
  </div></WithStats>
}

const SquadBet: React.FC<{bet: any, authToken: string}> = ({bet, authToken}) => {
  return <WithSquadBet authToken={authToken} initialBet={bet}>
    <_SquadBet />
  </WithSquadBet>
}

export default SquadBet
